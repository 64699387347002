<template>
  <div>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          Master Data
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          KEC Status Code
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- Filters -->
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-input-group>
              <b-input-group-prepend
                is-text
                v-b-toggle.filterS
                class="ll-outline">
                 <svg class="ll-ficon" aria-hidden="true">
                  <use xlink:href="#icon-filter"></use>
                </svg>
              </b-input-group-prepend>
              <b-form-input
                v-model="condition.userName"
                class="searchInput"
                placeholder="status code"
              />
              <b-input-group-prepend
                is-text
                class="ll-searchBtn"
                @click="searchById"
                >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-25"
                  size="18"
                />
              </b-input-group-prepend>
            </b-input-group>
          </b-col>
          <b-col v-if="false">
            <b-form-input
              v-model="userNList"
              class="searchInput"
              placeholder="username"
            />
          </b-col>
          <b-col class="ll-colBox" v-if="false">
            <b-button v-b-toggle.filterS>
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Filters sidebar -->
    <b-sidebar id="filterS" title="" right shadow backdrop bg-variant="white">
      <div class="px-3">
        <app-collapse class="ll-collapse">
          <app-collapse-item title="Customer">
            <v-select
              id="customer"
              v-model="condition.organizationId"
              :options="orgList"
              label="name"
              @input="getOrgId"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <app-collapse-item title="Status Type">
            <v-select
              v-model="condition.organizationId"
              :options="orgList"
              label="name"
              @input="getOrgId"
              placeholder=""
            ></v-select>
          </app-collapse-item>
          <app-collapse-item title="Status">
            <v-select
              id="isActive"
              :options="roleList"
              placeholder=""
            ></v-select>
          </app-collapse-item>
        </app-collapse>
        <div class="ll-btn">
          <b-button
            variant="secondary"
            class="mr-1"
            @click="onClear"
          >
            Clear
          </b-button>
          <b-button variant="info"
            @click="filterSearch">
            Search
          </b-button>
        </div>
      </div>
    </b-sidebar>

    <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :striped="true"
        :hover="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @sort-changed="onSortChange"
        >
        <!-- Column: Actions -->
        <template #cell(action)="data">
          <feather-icon
            :id="`${data.item.userId}`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'user-detail', params: { userId: data.item.userId }})"
          />
          <b-tooltip
            title="Edit"
            :target="`${data.item.userId}`"
          />
        </template>
      </b-table>
      <div class="d-flex justify-content-between flex-wrap ll-p">
          <div class="d-flex align-items-center mb-0 mr-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="size"
              :options="pageOptions"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalSize }} entries </span>
          </div>
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="size"
              first-number
              last-number
              class="mb-0 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
    </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageOptions: [50, 100, 200],
      page: null, // 当前页码
      size: 15, // 每页个数
      totalSize: null, // 总个数
      totalPage: null, // 总页数
      columns: [
        { key: 'userName', label: 'Code ID', sortable: true },
        { key: 'fullName', label: 'Code Name', sortable: true },
        { key: 'email', label: 'Code Descrption', sortable: true },
        { key: 'roles', label: 'Creation Time', sortable: true },
        { key: 'organizationId', label: 'Operator', sortable: true },
        { key: 'isEnabled', label: 'Status', sortable: true },
        { key: 'action', label: 'Action', class: 'll-action' },
      ],
      rows: [],
      searchTerm: '',
      roleList: [],
      orgList: [],
      statusList: [
        { id: false, statu: 'Locked' },
        { id: true, statu: 'Active' },
      ],
      formTime: '',
      toTime: '',
      condition: {
        page: '1',
        size: '50',
        startTime: '',
        endTime: '',
        roleName: '',
        status: '',
        orderMole: 0,
        orderBy: 'userId',
        organizationId: '',
        userName: '',
      },
      userNList: '',
    }
  },
  computed: {},
  created() {
    // this.getList()
  },
  mounted() {
    // this.getOrganization()
  },
  methods: {
    // 首次查询
    getList() {
      this.$http.post('/user/search', this.condition).then(res => {
        // console.log('ss', res.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.totalPages
        this.size = info.size
        this.totalSize = info.totalElements
        this.totalPage = info.totalPages
      }).catch(error => console.log(error))
    },
    // 点击单行
    onRowClick() {
      // console.log('onRowClick')
    },
    onSearch() {
      // console.log('onSearch')
    },
    onClear() {
      this.condition = {}
    },
    // 条件过滤搜索
    filterSearch() {
      this.$http.post('/user/search', this.condition).then(res => {
        // console.log('ss', res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
      }).catch(error => console.log(error))
    },
    // 排序规则
    onSortChange(params) {
      const orderBy = params.sortBy
      const orderMole = params.sortDesc ? 0 : 1
      console.log(orderBy, orderMole)
      return params.sortDesc ? 0 : 1
    },
    searchById() {
      this.condition.userName = this.condition.userName.trim()
      this.getList()
    },
    // 根据username查询
    handleSearch(searching) {
      this.condition.trackingIDs = searching.split(/[(\r\n)\r\n]+/)
      this.$http.post('/user/search', this.condition).then(res => {
        // console.log('TKID', res.data.data)
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
      }).catch(error => console.log(error))
    },
    handleChangePage(page) {
    //   console.log('page', page)
      this.condition.page = page
      this.$http.post('/user/search', this.condition).then(res => {
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
      })
    },
    handlePageChange(active) {
    //   console.log('active', active)
      this.condition.size = active
      this.$http.post('/user/search', this.condition).then(res => {
        const info = res.data.data
        this.rows = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
      })
    },
    // 查询组织信息
    getOrganization() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        // console.log(res.data.data)
        this.orgList = res.data.data
      })
    },
    // 获取组织ID
    getOrgId(id) {
      this.condition.organizationId = id.id
      this.getRoles()
    },
    // 通过组织ID查询角色信息
    getRoles() {
      this.$http.get('/admin/settings/role/getRolesByOrganization', { params: { orgId: this.condition.organizationId } }).then(res => {
        this.roleList = res.data.data
      })
    },
    getStatus(val) {
      this.condition.status = val.id
    },
  },
}
</script>
<style scoped>
/* .ll-p{
  position: fixed;
  bottom: 40px;
  right: 10px;
} */
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
</style>
